import { initFS } from '@fullstory/snippet';

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var fs = function fs() {
  return window[window._fs_namespace];
};

var ensureSnippetLoaded = function ensureSnippetLoaded() {
  var snippetLoaded = !!fs();

  if (!snippetLoaded) {
    throw Error('FullStory is not loaded, please ensure the init function is invoked before calling FullStory API functions');
  }
};

var hasFullStoryWithFunction = function hasFullStoryWithFunction() {
  ensureSnippetLoaded();

  for (var _len = arguments.length, testNames = new Array(_len), _key = 0; _key < _len; _key++) {
    testNames[_key] = arguments[_key];
  }

  return testNames.every(function (current) {
    return fs()[current];
  });
};

var guard = function guard(name) {
  return function () {
    if (window._fs_dev_mode) {
      var message = "FullStory is in dev mode and is not recording: ".concat(name, " method not executed");
      console.warn(message);
      return message;
    }

    if (hasFullStoryWithFunction(name)) {
      var _fs;

      return (_fs = fs())[name].apply(_fs, arguments);
    }

    console.warn("FS.".concat(name, " not ready"));
    return null;
  };
};

var event = guard('event');
var log = guard('log');
var getCurrentSessionURL = guard('getCurrentSessionURL');
var identify = guard('identify');
var setUserVars = guard('setUserVars');
var consent = guard('consent');
var shutdown = guard('shutdown');
var restart = guard('restart');
var anonymize = guard('anonymize');
var setVars = guard('setVars');

var _init = function _init(inputOptions, readyCallback) {
  var options = _objectSpread2({}, inputOptions);

  if (fs()) {
    console.warn('The FullStory snippet has already been defined elsewhere (likely in the <head> element)');
    return;
  }

  if (options.recordCrossDomainIFrames) {
    window._fs_run_in_iframe = true;
  }

  if (options.recordOnlyThisIFrame) {
    window._fs_is_outer_script = true;
  }

  if (options.cookieDomain) {
    window._fs_cookie_domain = options.cookieDomain;
  }

  if (options.debug === true) {
    if (!options.script) {
      options.script = 'edge.fullstory.com/s/fs-debug.js';
    } else {
      console.warn('Ignoring `debug = true` because `script` is set');
    }
  }

  initFS(options);

  if (readyCallback) {
    fs()('observe', {
      type: 'start',
      callback: readyCallback
    });
  }

  if (options.devMode === true) {
    var message = 'FullStory was initialized in devMode and will stop recording';
    event('FullStory Dev Mode', {
      message_str: message
    });
    shutdown();
    window._fs_dev_mode = true;
    console.warn(message);
  }
};

var initOnce = function initOnce(fn, message) {
  return function () {
    if (window._fs_initialized) {
      if (message) console.warn(message);
      return;
    }

    fn.apply(void 0, arguments);
    window._fs_initialized = true;
  };
};

var init = initOnce(_init, 'FullStory init has already been called once, additional invocations are ignored');
var isInitialized = function isInitialized() {
  return !!window._fs_initialized;
};

export { anonymize, consent, event, getCurrentSessionURL, identify, init, isInitialized, log, restart, setUserVars, setVars, shutdown };
